export default {
  colors: {
    schist: '#B0BB9B',
    alto: '#D9D9D9',
    kelp: '#454A3C',
    meteor: '#D37A0E',
    cloud: '#C1BEBA',
    kidnapper: '#DFE7D1',
    rangoonGreen: '#141412',
    heavyMetal: '#373B2F',
    white: '#FFF',
    gray: '#909090',
  },
  breakpoints: {
    xs: '(max-width: 575px)',
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
  },
  fonts: {
    sansSerif: 'Barlow, sans-serif',
    serif: 'IM Fell English, serif',
  },
};
